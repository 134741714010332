import React from 'react'
import { Form } from 'react-bootstrap'
import Privacy from './PrivacyFormConnection'

class GetBootstrapFields extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            form : null,
            fields: [],
            privacy: [],

            checkbox: {}
        }
    }

    handleChange = (event) => {
        let nam = event.target.name;
        let val = event.target.value;

        var newState = Object.assign({}, this.state);
        newState.checkbox[nam] = val;

        this.setState(newState)
        
        console.log(this.state)
    }

    getData = () => {
        const conn = new Privacy(this.props.token)
        conn.getForm(this.props.name)
        .then(response => {
            this.setState({
                form: response,
                fields: response.fields,
                privacy: response.privacy
            })
            let checkbox = {}
            response.privacy.forEach( (k) => {
                k.fields.forEach( (v) => {
                    checkbox[v.name] = '';
                })
            })

            this.setState({
                checkbox: checkbox
            })
        })
    }

    componentDidMount = () => {
        this.getData()
    }

    render() {
        return (
            <div>
                {this.state.fields.map( (v, index) =>                             
                    <Form.Group controlId={index} key={index}>
                        <Form.Label>{v.label}</Form.Label>
                        <Form.Control 
                            type={v.type} 
                            placeholder={v.placeholder} 
                            name={v.name} 
                            onChange={this.props.handleChange} />
                    </Form.Group>
                )}
                {this.state.privacy.map( (a, i) =>                             
                    <div key={`default-${i}`} className="mb-3">
                        <Form.Label 
                            dangerouslySetInnerHTML={{__html: a.description}}></Form.Label>
                        {a.fields.map( (k, o) => 
                            <Form.Check 
                                key={o} 
                                inline 
                                label={k.options.text} 
                                type={k.type} 
                                value={k.options.value} 
                                name={k.name} 
                                onChange={this.handleChange} required />
                        )}                                
                    </div>
                )}
            </div>
        )
    }
}

export default GetBootstrapFields