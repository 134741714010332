import React from 'react'
import Conn from '../Class/Connection'
import { Form, Button, Row, Col } from 'react-bootstrap'

import Alert from '../Components/Alert'
import Logo from '../assets/images/gruppo-maggioli.svg'


class RecoveryPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",

            alert: {
                event: false,
                name: "",
                msg: ""
            }
        }
    }

    handleChange = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }

    onSubmit = (event) => {
        event.preventDefault()
        Conn.post(
            global.config.api.passwordRecovery,
            JSON.stringify({
                "email": this.state.email
            })
        )
            .then(response => (response.json()))
            .then(data => {
                if (data.status) {
                    this.setState({
                        alert: {
                            event: true,
                            name: 'success',
                            msg: data.message
                        }
                    })

                } else {
                    this.setState({
                        alert: {
                            event: true,
                            name: 'danger',
                            msg: data.message
                        }
                    })
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Col sm={4} className="p-5 bg-light">
                    <Form onSubmit={this.onSubmit}>
                        <center className="mb-3">
                            <img
                                alt=""
                                src={Logo}
                                className="img-fluid d-inline-block align-top"
                            />
                        </center>
                        <h4>Recupera password</h4>
                        <Alert event={this.state.alert.event} name={this.state.alert.name} msg={this.state.alert.msg} />

                        <p>Inserisci il tuo indirizzo email di registrazione. Ti verrà inviata una email contenente le istruzioni per il recupero della tua password.</p>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Indirizzo email</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" name="email" value={this.state.email} onChange={this.handleChange} />
                        </Form.Group>

                        <Button variant="primary" type="submit" block>
                            Invia email
                </Button>
                        <div className="mt-3">
                            <p><a href="/login">Torna alla login</a></p>
                        </div>
                    </Form>
                </Col>
            </Row>
        )
    }
}

export default RecoveryPassword