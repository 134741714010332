class PrivacyForm {
    constructor(token) {
        this.token = token;

    }
    getAllForm = () => {

        let requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "token": this.token }),
            redirect: 'follow'
        };

        let response = fetch("https://privacy.maggiolicloud.it/get/form", requestOptions)

        return response;
    }

    getForm = async (nome) => {

        let result;
        await this.getAllForm()
        .then(response => response.json())
        .then(response => {
            response.form.forEach(element => {
                if(element.nome === nome)
                {
                    result = element
                }
            });
        })
        .catch()
        
        return result;
    }

    sendForm = (data) => {

        let requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        let response = fetch("https://privacy.maggiolicloud.it/api/v1/post/send", requestOptions)

        return response;
    }

    sendData = async (data) => {

        let response = await this.sendForm(data);
        let element = await response.json();

        return element;
        
    }
}

export default PrivacyForm