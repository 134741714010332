import Cookies from 'universal-cookie'
const cookie = new Cookies();

class Connection {
    async post(url, param) {
        const response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': global.config.cors
            },
            body: param
        });
        return response;
    }

    async get(url) {
        const response = await fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': global.config.cors
            }
        });

        return response;
    }

    async postToken(url, param) {
        const response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': global.config.cors,
                'Authorization' : 'Bearer ' + cookie.get("token")
            },
            body: param
        });
        return response;
    }

    async getToken(url) {
        const response = await fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': global.config.cors,
                'Authorization' : 'Bearer ' + cookie.get("token")
            }
        });

        return response;
    }
}

export default new Connection()