const api = "https://prontuari.maggiolicloud.it/api/auth/v1/"
const url = "https://prontuari.maggiolicloud.it/api/"

module.exports = global.config = {
    url: "https://prontuari.maggiolicloud.it/api/",

    /**
     * Configuro tutti gli End-Point
     */
    api: {
        /**
         * No required token
         */
        login: url + "users/login/index",
        passwordRecovery: url + "users/login/passwordrecovery",
        passwordKeyCodeCheck: url + "users/login/keycodecheck",
        passwordReset: url + "users/login/passwordreset",
        register: url + "users/register/index",
        activeAccount: url + "users/register/active",



        getUsers: api + "get/users/index",
        getUser: api + "get/users/user", // richiede obbligatoriamente l'id per gestire la richiesta(user/id)
        profile: api + "get/users/profile" // ritorna i dati del profilo, recuperandoli dal token inviato
    },


    cors: "https://prontuari.maggiolicloud.it"
}
