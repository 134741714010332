import React from 'react';
import Router from './Layout/Router'

import {Container} from 'react-bootstrap'

const App = props => {
    return (
        <Container>
            <Router />
        </Container>
    )
}

export default App
