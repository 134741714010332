import React from 'react'
import Conn from '../Class/Connection'
import {Form, Button, Row, Col} from 'react-bootstrap'
import Cookies from 'universal-cookie'
import Alert from '../Components/Alert'
import Logo from '../assets/images/gruppo-maggioli.svg'

const cookie = new Cookies()

class Login extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            email: "",
            password: "",

            alert: {
                event:false,
                name: "",
                msg: ""
            }
        }
    }

    handleChange = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }

    onSubmit = (event) => {
        event.preventDefault()
        Conn.post(
            global.config.api.login,
            JSON.stringify({
                "email" : this.state.email,
                "password" : this.state.password
            })
        )
        .then(response => (response.json()))
        .then(data => {
            if( data.status )
            {
                cookie.set("token", data.token, {
                    path: '/', 
                    expires: this.getExpire()
                })

                this.setState({
                    alert: {
                        event: true,
                        name: 'success',
                        msg: data.message
                    }
                })

                setTimeout(() => {
                    window.location.href = "/admin/";
                }, 2000)
                
            } else {
                this.setState({
                    alert: {
                        event: true,
                        name: 'danger',
                        msg: data.message
                    }
                })
            }
        })
        .catch(error => {
            console.log(error)
        })
    }

    getExpire() {
        const timestamp = new Date().getTime();
        const expire = timestamp + (60 * 60 * 24 * 1000 * 3);
        const expireDate = new Date(expire);
        return expireDate;
    }
    render() {
        return (
            <Row className="justify-content-center">
                <Col sm={4} className="p-5 bg-light">
                    <Form onSubmit={this.onSubmit}>
                        <center className="mb-3">
                            <img
                                alt=""
                                src={Logo}
                                className="img-fluid d-inline-block align-top"
                            />
                        </center>
                        <h4>Accedi</h4>
                        <Alert event={this.state.alert.event} name={this.state.alert.name} msg={this.state.alert.msg} />
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Indirizzo email</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" name="email" value={this.state.email} onChange={this.handleChange} />                    
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" value={this.state.password} name="password" onChange={this.handleChange} />
                        </Form.Group>
                        <Button variant="primary" type="submit" block>
                            Accedi
                        </Button>
                        <div className="mt-3">
                            <p><a href="/register">Non hai un account? Registrati</a></p>
                            <p><a href="/recovery-password">Recupera password</a></p>
                        </div>
                    </Form>
                </Col>
            </Row>            
        )
    }
}

export default Login
