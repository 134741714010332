import React from 'react'
import Conn from '../Class/Connection'
import { Form, Button, Row, Col } from 'react-bootstrap'
import Alert from '../Components/Alert'
import Logo from '../assets/images/gruppo-maggioli.svg'

class NewPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            password2: "",
            key_code: this.props.match.params.key,

            alert: {
                event: false,
                name: "",
                msg: ""
            }
        }
    }

    handleChange = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }

    keyCodeCheck = () => {
        Conn.post(
            global.config.api.passwordKeyCodeCheck,
            JSON.stringify({
                "key_code": this.state.key_code
            })
        )
            .then(response => (response.json()))
            .then(data => {
                if (data.status === false)
                    window.location.href = "/notFound"
            })
            .catch(error => {
                console.log(error)
            })
    }

    onSubmit = (event) => {
        event.preventDefault()
        Conn.post(
            global.config.api.passwordReset,
            JSON.stringify({
                "email": this.state.email,
                "password": this.state.password,
                "password2": this.state.password2,
                "key_code": this.state.key_code
            })
        )
            .then(response => (response.json()))
            .then(data => {
                if (data.status) {
                    this.setState({
                        alert: {
                            event: true,
                            name: 'success',
                            msg: data.message
                        }
                    })

                    setTimeout(() => {
                        window.location.href = "/";
                    }, 2000)

                } else {
                    this.setState({
                        alert: {
                            event: true,
                            name: 'danger',
                            msg: data.message
                        }
                    })
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    componentDidMount() {
        this.keyCodeCheck();
    }

    render() {

        return (
            <Row className="justify-content-center">
                <Col sm={4} className="p-5 bg-light">
                    <Form onSubmit={this.onSubmit}>
                        <center className="mb-3">
                            <img
                                alt=""
                                src={Logo}
                                className="img-fluid d-inline-block align-top"
                            />
                        </center>
                        <h4>Nuova password</h4>
                        <Alert event={this.state.alert.event} name={this.state.alert.name} msg={this.state.alert.msg} />
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Indirizzo email</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" name="email" value={this.state.email} onChange={this.handleChange} />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" value={this.state.password} name="password" onChange={this.handleChange} />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Ripeti Password</Form.Label>
                            <Form.Control type="password" placeholder="Ripeti Password" value={this.state.password2} name="password2" onChange={this.handleChange} />
                        </Form.Group>
                        <Button variant="primary" type="submit" block>
                            Modifica la password
                </Button>
                    </Form>
                </Col>
            </Row>
        )
    }
}

export default NewPassword