import React from 'react'
import { Form, Button, Container, Row, Col } from 'react-bootstrap'
import Logo from '../assets/images/gruppo-maggioli.svg'

import Cookies from 'universal-cookie'
import {UserData} from './UserData'
import Conn from '../Class/Connection'
import Alert from '../Components/Alert'
const Apple = require('../assets/images/applestore.png')
const Google = require('../assets/images/googleplay.png')

const cookie = new Cookies()



const Home = () => {

    const [user, setUser] = React.useState([])
    const [guid, setGuid] = React.useState("")
    const [alert, setAlert] = React.useState({
        event: false,
        name: "",
        msg: ""
    })

    const setLicense = e => {
        e.preventDefault()
        const obj =  {
            user_id: user.id,
            guid: guid
        }
        Conn.postToken(global.config.api.setLicense, JSON.stringify(obj))
        .then( response => response.json())
        .then( data => {
            if( ! data.status )
            {
                setAlert({
                    event: true,
                    name: "danger",
                    msg: data.message})
            } else {
                setAlert({
                    event: true,
                    name: "success",
                    msg: data.message})

                setTimeout(() => window.location.reload(), 2000)
            }
        })
    }

    return (
        <>
        <Container className="pt-5">
            <Row className="align-items-center justify-content-center bg-gray bg-light">
                <Col md={6}>
                    <Row className="align-items-center justify-content-center">
                        <Col xs={"4"}>

                            <center className="mb-3">
                                <img
                                    alt=""
                                    src={Logo}
                                    className="img-fluid d-inline-block align-top"
                                />
                            </center>
                        </Col>
                        <Col xs={"8"}>

                            <h1>Prontuari<br/>Maggioli</h1>
                        </Col>
                    </Row>
                </Col>
                <Col md={"6"}>
                    {!cookie.get("token") ?
                        <div>
                            <h3>Accedi</h3>
                            <p>Entra oppure registrati a prontuari.maggiolicloud.it,<br />
                            potrai riscattare il codice che trovi nel libro ed avrai accesso completo all'applicazione Prontuario del codice della strada</p>
                            
                            <div>
                                <a href="/login" className="btn btn-primary btn-xs">Accedi</a>
                                <a href="/register" className="btn btn-outline-primary btn-xs ml-3">Registrati</a>
                            </div>
                        </div>
                    : 
                        <div>
                            <h3>Hai un codice da riscattare?</h3>
                            <Alert event={alert.event} name={alert.name} msg={alert.msg} />                             
                            <Form onSubmit={e => setLicense(e)}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Inserisci il codice che trovi sul libro e clicca riscatta.</Form.Label>
                                    <Form.Control type="text" placeholder="Inserisci il tuo codice" value={guid} onChange={e => setGuid(e.target.value)} />                    
                                </Form.Group>
                                <Button variant="primary" type="submit" block>
                                    Riscatta
                                </Button>
                            </Form>
                        </div>
                    }
                </Col>
            </Row>
        </Container>
        {cookie.get("token") ? <UserData cookie={cookie} Data={setUser} /> : 
        <Container className={"py-3"}>
            <Row className="justify-content-center">
                <Col md={"12"}>
                    <p>L’App consente la rapida consultazione del “Prontuario delle violazioni al codice della strada”, anche in mobilità sul tuo smartphone.</p>
                    <p>Per ogni articolo del codice della strada sono dettagliatamente elencati: i commi di riferimento, le violazioni, tutti gli importi delle sanzioni applicabili, i punti da decurtare se previsto, le eventuali sanzioni accessorie e le indicazioni da apporre sul verbale.</p>
                    <p>Sono inoltre immediatamente consultabili le note operative ed esplicative.</p>
                    <p>Uno strumento essenziale per il lavoro, un dispositivo irrinunciabile per chi è quotidianamente impegnato nelle attività di polizia stradale a garanzia della sicurezza della circolazione e del traffico.</p>
                    <p>L’acquisto del volume cartaceo include l’accesso al sito per un costante aggiornamento fino al 31 dicembre 2021.</p>
                    <p>Con il medesimo codice, allegato al volume, potrete accedere ai contenuti del sito e alla versione completa della App, previa registrazione gratuita.</p>
                    <Row>
                        <Col md={"3"} className={"py-3"}>
                            <a href={"https://play.google.com/store/apps/details?id=it.maggioli.prontuarioCDS&hl=it&gl=US"} target="_blank" rel="noopener noreferrer"><img src={Google} className={"img-fluid"} alt="" /></a>
                        </Col>
                        <Col md={"3"} className={"py-3"}>
                            <img src={Apple} className={"img-fluid"} alt="" />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>}
        </>
    )
}

export default Home