import React from 'react'
import { Table, Container, Row, Col } from 'react-bootstrap'
import Conn from '../Class/Connection'
const Apple = require('../assets/images/applestore.png')
const Google = require('../assets/images/googleplay.png')


const __getUser = async () => {
    return await Conn.getToken(global.config.api.profile)
}

const UTCDate = (iso) => {
    let date = new Date(iso);

    return `${date.getUTCDate()}/${date.getUTCMonth() +1}/${date.getUTCFullYear()}`
}

export const UserData = ({ cookie, Data }) => {

    const [user, setUser] = React.useState([])
    const [licenses, setLicenses] = React.useState([])

    const getUser = () => {
        __getUser().then(
            response => response.json()
        )
        .then(
            data => {
                setUser(data.data)
                setLicenses(data.data.licenses ? data.data.licenses : [])
                Data(data.data)
            }
        )
    }

    React.useEffect(() => {
        getUser()
        // eslint-disable-next-line
    }, [])

    const logout = async (e) => {
        e.preventDefault()

        await cookie.remove("token")
        setTimeout(() => window.location.reload(), 1000)
    }

    return (
        <Container className={"py-3"}>
            <Row>
                <Col md={"4"}>
                    <h3>I tuoi dati</h3>
                    <p>Nome: {user.name}</p>
                    <p>Cognome: {user.surname}</p>
                    <p>e-mail: {user.email}</p>
                    <p><a href="#/" onClick={(e) => logout(e)} className={"btn btn-primary btn-small btn-block"}>Logout</a></p>
                    {user.role === "12" && <p><a href="https://prontuari.maggiolicloud.it/admin/" rel="noopener noreferrer" target="_blank" className={"btn btn-outline-primary btn-small btn-block"}>Amministrazione</a></p>}
                </Col>
                <Col md={"8"}>
                    <Row className={"justify-content-between"}>
                        <Col xs={"4"} className={"py-3"}>
                            <a href={"https://play.google.com/store/apps/details?id=it.maggioli.prontuarioCDS&hl=it&gl=US"} target="_blank" rel="noopener noreferrer"><img src={Google} className={"img-fluid"} alt="" /></a>
                        </Col>
                        <Col xs={"4"} className={"py-3"}>
                            <img src={Apple} className={"img-fluid"} alt="" />
                        </Col>
                    </Row>
                    <h3>Licenze attive sul tuo account</h3>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>guid</th>
                                <th>prontuario</th>
                                <th>stato</th>
                                <th>tipo</th>
                                <th>scadenza</th>
                            </tr>
                        </thead>
                        <tbody>
                             {
                                licenses.map((v,i) =>
                                    <tr key={i}>
                                        <td>{v.guid}</td>
                                        <td>{v.prontuario.title}</td>
                                        <td>{v.license.active === "1" ? "attiva" : "Non attiva"}</td>
                                        <td>{v.license.type}</td>
                                        <td>{UTCDate(v.license.end_date)}</td>
                                    </tr> 
                                )
                             }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    )
}