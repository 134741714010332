import {Alert} from 'react-bootstrap'
import React from 'react'

const MaggioliAlert = (props) => {
  if (props.event) {
    return (
      <Alert variant={props.name}>
        <p>{props.msg}</p>
      </Alert>
    );
  }
  return null
}

export default MaggioliAlert