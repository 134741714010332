import React from 'react'
import Conn from '../Class/Connection'
import { Form, Button, Row, Col } from 'react-bootstrap'
import Alert from '../Components/Alert'
import Logo from '../assets/images/gruppo-maggioli.svg'
/**
 * import from libraries Privacy Module
 */
import GetBootstrapFields from '../Libraries/React-PrivacyForm/GetBootstrapFields'

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.output = this.handleChange.bind(this)
        this.state = {
            nome: "",
            cognome: "",
            email: "",
            password: "",
            password2: "",
            token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJfIiwiYXVkIjoxLCJpYXQiOjE1OTE3MDY2MDcsIm5iZiI6MTU5MTcwNjYwNywiZXhwIjoxOTA3MDY2NjA3LCJkYXRhIjp7ImlkIjo3LCJzaXRlX2lkIjoxMH19.h7Kww3i5ycKTlIoVTTNDK8XitNTKukIFLOgb-uplVbc",
            alert: {
                event: false,
                name: "",
                msg: ""
            }
        }
    }

    handleChange = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }

    onSubmit = (event) => {
        event.preventDefault()
        Conn.post(
            global.config.api.register,
            JSON.stringify({
                "name": this.state.nome,
                "surname": this.state.cognome,
                "email": this.state.email,
                "password": this.state.password,
                "password2": this.state.password2
            })
        )
        .then(response => (response.json()))
        .then(data => {
            if (data.status) {                
                this.setState({
                    alert: {
                        event: true,
                        name: 'success',
                        msg: data.message
                    }
                })

            } else {                
                this.setState({
                    alert: {
                        event: true,
                        name: 'danger',
                        msg: data.message
                    }
                })
            }
        })
        .catch(error => {
            console.log(error)
        })
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Col sm={6} className="p-5 bg-light">
                    <Form onSubmit={this.onSubmit}>
                        <center className="mb-3">
                            <img
                                alt=""
                                src={Logo}
                                className="img-fluid d-inline-block align-top"
                            />
                        </center>
                        <h4>Registrati</h4>
                        <Alert event={this.state.alert.event} name={this.state.alert.name} msg={this.state.alert.msg} />
                        
                        <GetBootstrapFields name="register" token={this.state.token} handleChange={this.output} />
                        
                        <Button variant="primary" type="submit" block>
                            Registrati
                        </Button>
                        <div className="mt-3">
                            <p><a href="/login">Hai un account? Effettua il login</a></p>
                            <p><a href="/recovery-password">Recupera password</a></p>
                        </div>
                    </Form>
                </Col>
            </Row>
        )
    }
}

export default Register