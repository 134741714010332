import React from 'react'
import Conn from '../Class/Connection'
import Logo from '../assets/images/gruppo-maggioli.svg'
import { Row, Col } from 'react-bootstrap'

/**
 * modificare il messaggio di avvenuta attivazione
 */
class ActiveAccount extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            msg: ""
        }
    }

    activate = () => {
        Conn.post(
            global.config.api.activeAccount,
            JSON.stringify({
                "id": this.props.match.params.id,
                "key_code": this.props.match.params.key
            })
        )
            .then(response => (response.json()))
            .then(data => {
                this.setState({
                    msg: data.message
                })
                /* if (data.status === true) {
                    setTimeout(() => {
                        window.location.href = "/"
                    }, 3000)
                } */
            })
    }
    componentDidMount() {
        this.activate()
    }
    render() {
        return (
            <div>
                <Row className="justify-content-center">
                    <Col sm={4} className="p-5 bg-light">
                        <center className="mb-3">
                            <img
                                alt=""
                                src={Logo}
                                className="img-fluid d-inline-block align-top"
                            />
                        </center>
                        <div>{this.state.msg}</div>
                        <div className={"mt-5"}>Oppure<br /><a href="/login" className="btn btn-primary btn-xs btn-block">Accedi</a></div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default ActiveAccount