import React from 'react'
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'

import Home from '../Views/Home'
import NotFound from '../Views/404'

// Routing login page
import Login from '../Views/Login'
import RecoveryPassword from '../Views/RecoveryPassword'
import NewPassword from '../Views/NewPassword'
import Register from '../Views/Register'
import ActiveAccount from '../Views/ActiveAccount'

import Cookies from 'universal-cookie'
const cookie = new Cookies()

const routing = (
  <Router>
    <Switch>
      <Route exact path="/" component={Home} />
      
      {!cookie.get("token") ? 
        <React.Fragment>
          <Route path="/login" component={Login} />
          <Route path="/recovery-password" component={RecoveryPassword} />
          <Route path="/new-password/:key" component={NewPassword} />
          <Route path="/active-account/:id/:key" component={ActiveAccount} />
          <Route path="/register" component={Register} />
        </React.Fragment>
      : <Redirect to="/" />}

      <Route component={NotFound} />
    </Switch>
  </Router>
)

class Wrapper extends React.Component
{
    render() {
        return routing
    }
}

export default Wrapper